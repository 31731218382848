@import 'webresources-sources/scss/imports';

.video-component {
  .headlines-container,
  .text-container {
    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-right: auto;
      width: calc((8 / 12) * 100%);
    }
  }

  .video-container {
    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-right: auto;
      width: calc((8 / 12) * 100%);
    }

    video {
      cursor: pointer;
    }

    .has-poster {
      iframe.embed-responsive-item {
        z-index: 1;
        pointer-events: none;
      }

      img.youtube-poster {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }

      .youtube-poster-action {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;

        &:hover {
          cursor: pointer;

          img {
            opacity: 0.7;
          }
        }

        svg {
          width: 100%;
        }
      }

      &.poster-clicked {
        iframe.embed-responsive-item {
          pointer-events: all;
        }

        img.youtube-poster,
        .youtube-poster-action {
          display: none;
        }
      }
    }
  }

  .cookie-consent-banner {
    font-size: 16px;
    font-weight: 400;
  }
}
