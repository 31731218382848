/* bootstrap overwrite */
@media (min-width: 992px) {
  .video-component .headlines-container,
.video-component .text-container {
    margin-left: auto;
    margin-right: auto;
    width: calc((8 / 12) * 100%);
  }
}
@media (min-width: 992px) {
  .video-component .video-container {
    margin-left: auto;
    margin-right: auto;
    width: calc((8 / 12) * 100%);
  }
}
.video-component .video-container video {
  cursor: pointer;
}
.video-component .video-container .has-poster iframe.embed-responsive-item {
  z-index: 1;
  pointer-events: none;
}
.video-component .video-container .has-poster img.youtube-poster {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.video-component .video-container .has-poster .youtube-poster-action {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.video-component .video-container .has-poster .youtube-poster-action:hover {
  cursor: pointer;
}
.video-component .video-container .has-poster .youtube-poster-action:hover img {
  opacity: 0.7;
}
.video-component .video-container .has-poster .youtube-poster-action svg {
  width: 100%;
}
.video-component .video-container .has-poster.poster-clicked iframe.embed-responsive-item {
  pointer-events: all;
}
.video-component .video-container .has-poster.poster-clicked img.youtube-poster,
.video-component .video-container .has-poster.poster-clicked .youtube-poster-action {
  display: none;
}
.video-component .cookie-consent-banner {
  font-size: 16px;
  font-weight: 400;
}